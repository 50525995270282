@import url(https://fonts.googleapis.com/css?family=Space+Mono:regular,italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic);

.hero {
    background: linear-gradient(
        65deg, 
        #d34926 0%,
        #df5d07 50%,
        #ecb16d 100%
    );
    height: auto;
    width: 100vw;
    margin: 0;
}

.title {
    font-size: 120px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    letter-spacing: -3px;
    transition: 700ms ease;
    font-variation-settings: "wght" 311;
    margin-bottom: 0.8rem;
    color: PaleGoldenRod;
    outline: none;
    text-align: center;
    margin: 0;
}

.title:hover {
    font-variation-settings: "wght" 582; 
    letter-spacing: 1px;
  }

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45vw, 1fr));
    gap: 1.5rem;
    padding: 1rem;
    justify-content: center;
    border-color: white;
    font-size: 2rem;
    overflow: hidden;
}

.card-container {
    position: relative;
    background-color: #fefefe;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.541);
    height: 500px;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.card-container:hover {
    transform: scale(1.05);
}

iframe {
    position: absolute;
    overflow: hidden;
    width: 1920px;
    height: 1080px;
    border-radius: 1.5rem;
    box-shadow: 10px 10px 50px rgba(41, 20, 2, 0.603);
    pointer-events: none;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.iframe-div {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 40%;
    width: 100%;
    transform: scale(0.15);
}

.status {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.status span {
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 2rem;
    font-family: 'Roboto', sans-serif;
}

.status .up {
    background-color: #e6f4ea;
    color: #137333;
}

.status .down {
    background-color: #fce8e6;
    color: #c5221f;
}

.uptime {
    font-size: 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

.uptime .up {
    background-color: #e6f4ea;
    color: #137333;
}

.uptime .down {
    background-color: #fce8e6;
    color: #c5221f;
}


.iframe-div p {
    font-size: 7rem;
    color: #c5221f;
}

@media (min-width: 1080px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30vw, 1fr));
        gap: 1.5rem;
        padding: 1rem;
        justify-content: center;
        border-color: white;
        font-size: 2rem;
        overflow: hidden;
    }
  }

@media (max-width: 1080px) {
    .title {
        font-size: 80px;
    }

    .status {
        font-size: 1.5rem;
    }

    .uptime {
        font-size: 1.5rem;
    }
}

